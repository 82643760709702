import React from "react";

const Problems = () => {
  return (
    <section className="section items-center" id="problems">
      <div className="container flex-column justify-center items-center text-center text-primary">
        <div className="section__header">
          <h2 className="font-normal text-48">However...</h2>
          <p className="font-normal">
            As attorneys, navigating the expansive (and often, also expensive)
            array of AI tools tailored to the legal profession can be a
            confusing, if not impossible, task.
          </p>
          {/* <br /> */}
          <p className="font-normal">
            Moreover, these innovative technologies do not come with essential{" "}
            <span className={"emboldentext"}>
              implementation, training, consultative, and integration{" "}
            </span>
            services.
          </p>
          <p className="font-normal">
            And good luck trying to get{" "}
            <span className={"emboldentext"}>customer support</span> from anyone
            besides a chatbot!
          </p>
          <p className="font-normal">
            Harnessing the full power of these tools to produce secure, reliable
            results for your attorneys and clients is a job in and of itself.
          </p>
          {/* <br /> */}
          <p className="font-bold">
            Fear not...for these very services are our specialty!
            <br />
          </p>
        </div>
      </div>
    </section>
  );
};

export default Problems;
