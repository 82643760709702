import React, { useState } from "react";
import { Button } from "@mui/material";

const Form = () => {
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    "entry.15074004": "",
    "entry.1913473230": "",
    "entry.2135862074": "",
    "entry.441289859": "",
  });

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmit(true);

    let url = `https://docs.google.com/forms/u/0/d/e/1FAIpQLScZ-w6o0FXXtQ5webmCcI0pYkHP7boxjjpMckZPlIV6tsiRQg/formResponse?entry.15074004=${formData["entry.15074004"]}&entry.1913473230=${formData["entry.1913473230"]}&entry.2135862074=${formData["entry.2135862074"]}&entry.441289859=${formData["entry.441289859"]}`;

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  return (
    <div className="contactFormWrapper">
      <div className="formheader"></div>
      <div className="formcontact">
        {submit ? (
          <div className="afterForm">
            Thank you, a member of our team will get back to you shortly
          </div>
        ) : (
          <form onSubmit={handleSubmit} target="_self">
            <fieldset>
              <label htmlFor="entry.15074004">Name:</label>
              <input
                type="text"
                name="entry.15074004"
                onChange={handleInputData("entry.15074004")}
                value={formData["entry.15074004"]}
              />
            </fieldset>

            <fieldset>
              <label htmlFor="entry.1913473230">Organization:</label>
              <input
                type="text"
                name="entry.1913473230"
                onChange={handleInputData("entry.1913473230")}
                value={formData["entry.1913473230"]}
              />
            </fieldset>

            <fieldset>
              <label htmlFor="entry.2135862074">
                To better help our engineers, please briefly explain your
                intended use for our software:
              </label>
              <textarea
                type="text"
                rows="4"
                name="entry.2135862074"
                onChange={handleInputData("entry.2135862074")}
                value={formData["entry.2135862074"]}
              />
            </fieldset>

            <fieldset>
              <label htmlFor="entry.441289859">E-mail:</label>
              <input
                required
                type="email"
                name="entry.441289859"
                onChange={handleInputData("entry.441289859")}
                value={formData["entry.441289859"]}
              />
            </fieldset>

            <Button
              type="submit"
              className="box-shadow-none px-8 hover-bg-secondary capitalize submit-form"
              variant="contained"
              color="secondary"
            >
              Get Started
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Form;
