import React, { useEffect } from "react";
import { scrollTo } from "../utils";
import Footer from "./sections/Footer";
import TopBar from "./sections/TopBar";
import PrivacyPolicy from "./sections/PrivacyPolicy";

const Home = () => {
  useEffect(() => {
    scrollTo("root");
  });

  return (
    <div className="landing">
      <TopBar />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default Home;
