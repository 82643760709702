import React from "react";
import {
  Route,
  Switch,
  HashRouter as Router,
  Redirect,
} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import Scrollbar from "react-perfect-scrollbar";
import { Theme } from "./theme";

import "react-perfect-scrollbar/dist/css/styles.css";
import GlobalCss from "./styles/jss/GlobalCss";

import Home from "./home/Home";
import RequestDemoPage from "./home/RequestDemoPage";
import PrivacyPage from "./home/PrivacyPage";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalCss>
        <Scrollbar
          className="h-full-screen scrollable-content"
          option={{ suppressScrollX: true }}
        >
          <Router basename="/">
            <Switch>
              <Route path="/home" component={Home} />
              <Route path="/requestdemo" component={RequestDemoPage} />
              <Route path="/privacypolicy" component={PrivacyPage} />
              <Redirect path="/" exact to="/home" />

              {/* <Route component={Error} /> */}
            </Switch>
          </Router>
        </Scrollbar>
      </GlobalCss>
    </ThemeProvider>
  );
}

export default App;
