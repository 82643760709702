import React, { useEffect } from "react";
import { scrollTo } from "../utils";
import Footer from "./sections/Footer";
import Intro from "./sections/Intro";
import TopBar from "./sections/TopBar";
import LegalAI from "./sections/LegalAI";
import Problems from "./sections/Problems";
import Bottom from "./sections/Bottom";
import Solutions from "./sections/Solutions";
import Team from "./sections/Team";
import Clients from "./sections/Clients";
import Subscription from "./sections/Subscription";

const Home = () => {
  useEffect(() => {
    scrollTo("root");
  });

  return (
    <div className="landing">
      <TopBar />
      <Intro />
      <LegalAI />
      <Problems />
      <Solutions />
      <Clients />
      <Team />
      <Subscription />
      <Bottom />
      <Footer />
    </div>
  );
};

export default Home;
