import React, { useEffect } from "react";
import { scrollTo } from "../utils";
import Footer from "./sections/Footer";
import RequestDemo from "./sections/RequestDemo";
import TopBarDemo from "./sections/TopBarDemo";

const Home = () => {
  useEffect(() => {
    scrollTo("root");
  });

  return (
    <div className="landing">
      <TopBarDemo />
      {/* <Intro />
      <LegalAI />
      <Problems />
      <Solutions />
      <Clients />
      <Team />
      <Subscription />
      <Bottom /> */}
      <RequestDemo />
      <Footer />
    </div>
  );
};

export default Home;
