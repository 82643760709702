import React, { useState, useEffect } from "react";
import { debounce, classList } from "../../utils";
import { Icon } from "@mui/material";
import { IconButton } from "@mui/material";
import ScrollTo from "../common/ScrollTo";
import { Button } from "@mui/material";

const TopBar6 = (props) => {
  const [isTop, setIsTop] = useState(true);
  const [isClosed, setIsClosed] = useState(true);

  let scrollableElement = document.querySelector(".scrollable-content");
  if (!scrollableElement) scrollableElement = window;

  let handleScrollRef = null;
  let toggleIcon = isClosed ? "menu" : "close";

  const handleScroll = () => {
    return debounce(({ target: { scrollTop } }) => {
      let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
      setIsTop(isCurrentTop);
    }, 20);
  };

  handleScrollRef = handleScroll();

  const close = () => {
    setIsClosed(false);
  };

  useEffect(() => {
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScrollRef);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScrollRef);
      }
    };
  }, [scrollableElement, handleScrollRef]);

  return (
    <section
      className={classList({
        header: true,
        "header-fixed": !isTop,
        closed: isClosed,
      })}
    >
      <div className="header-container ml-4 mr-4">
        <div className="brand">
          {/* <ScrollTo to="" onScroll={close} className={"logo-link"}> */}
          <a
            className={"logo-link text-white font-bold text-20"}
            href={"/"}
            onClick={close}
          >
            CommonLawAI
            {/* <img
              src="/assets/images/CommonLawAI-wordmark-white.png"
              alt="Common Law Logo"
            /> */}
          </a>
          {/* </ScrollTo> */}
        </div>
        <ul className="navigation">
          <li>
            <ScrollTo to="legalai" onScroll={close}>
              Legal AI
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="problems" onScroll={close}>
              Problems
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="solutions" onScroll={close}>
              How We Help
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="clients" onScroll={close}>
              Who We Serve
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="team" onScroll={close}>
              About Us
            </ScrollTo>
          </li>
        </ul>
        <div className="m-auto" />
        <div className="navigation flex">
          <ScrollTo to="getstarted" onScroll={close}>
            <Button
              className="box-shadow-none px-8 hover-bg-primary capitalize topbar-btn"
              variant="contained"
              color="primary"
            >
              Free AI Consultation
            </Button>
          </ScrollTo>
        </div>
        <IconButton
          className="header__toggle"
          onClick={() => {
            setIsClosed(!isClosed);
          }}
        >
          <Icon>{toggleIcon}</Icon>
        </IconButton>
      </div>
    </section>
  );
};

export default TopBar6;
