import React from "react";
import Form from "./Form";
import Grid from "@mui/material/Grid";

const Bottom = () => {
  return (
    <section className="section items-center" id="tweets">
      <div className="container flex-column justify-center items-center text-center text-primary">
        <div className="section__header">
          <h2 className="font-normal text-48">Legal AI in the news</h2>
        </div>{" "}
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {" "}
            <blockquote class="twitter-tweet">
              <p lang="en" dir="ltr">
                Use technology to free yourself from menial duties and elevate
                your legal career.{" "}
                <a href="https://t.co/wrfWRDK1Vy">https://t.co/wrfWRDK1Vy</a>
              </p>
              &mdash; Above the Law (@atlblog){" "}
              <a href="https://twitter.com/atlblog/status/1632799780530266113?ref_src=twsrc%5Etfw">
                March 6, 2023
              </a>
            </blockquote>{" "}
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            ></script>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {" "}
            <blockquote class="twitter-tweet">
              <p lang="en" dir="ltr">
                ABA Techshow vendors relish a return to the exhibition floor
                amid buzz about ChatGPT.{" "}
                <a href="https://t.co/SbF0AYW8YT">https://t.co/SbF0AYW8YT</a>{" "}
                <a href="https://twitter.com/hashtag/ABATechshow?src=hash&amp;ref_src=twsrc%5Etfw">
                  #ABATechshow
                </a>{" "}
                <a href="https://twitter.com/hashtag/ABATechshow2023?src=hash&amp;ref_src=twsrc%5Etfw">
                  #ABATechshow2023
                </a>{" "}
                <a href="https://twitter.com/hashtag/ChatGPT?src=hash&amp;ref_src=twsrc%5Etfw">
                  #ChatGPT
                </a>{" "}
                <a href="https://twitter.com/mreynoldswriter?ref_src=twsrc%5Etfw">
                  @mreynoldswriter
                </a>{" "}
                <a href="https://twitter.com/ABATECHSHOW?ref_src=twsrc%5Etfw">
                  @ABATECHSHOW
                </a>{" "}
                <a href="https://twitter.com/ABAesq?ref_src=twsrc%5Etfw">
                  @ABAesq
                </a>
              </p>
              &mdash; ABA Journal (@ABAJournal){" "}
              <a href="https://twitter.com/ABAJournal/status/1631665099944480770?ref_src=twsrc%5Etfw">
                March 3, 2023
              </a>
            </blockquote>{" "}
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            ></script>
          </Grid>{" "}
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {" "}
            <blockquote class="twitter-tweet">
              <p lang="en" dir="ltr">
                Working To Incorporate Legal Technology Into Your Practice Isn’t
                Just A Great Business Move — It’s Required{" "}
                <a href="https://t.co/jv6I1yFc8z">https://t.co/jv6I1yFc8z</a>
              </p>
              &mdash; Evolve the Law (@EvolveLawNow){" "}
              <a href="https://twitter.com/EvolveLawNow/status/1631783696930861057?ref_src=twsrc%5Etfw">
                March 3, 2023
              </a>
            </blockquote>{" "}
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            ></script>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {" "}
            <blockquote class="twitter-tweet">
              <p lang="en" dir="ltr">
                Casetext&#39;s Co-Counsel thinks like a good junior lawyer,
                which is exactly what lawyers need from AI.{" "}
                <a href="https://t.co/15IEfWLru2">https://t.co/15IEfWLru2</a>
              </p>
              &mdash; Above the Law (@atlblog){" "}
              <a href="https://twitter.com/atlblog/status/1631064320321757189?ref_src=twsrc%5Etfw">
                March 1, 2023
              </a>
            </blockquote>{" "}
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            ></script>
          </Grid>{" "}
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {" "}
            <blockquote class="twitter-tweet">
              <p lang="en" dir="ltr">
                AI Won’t Replace All Lawyers… Just The Lazy Ones{" "}
                <a href="https://t.co/UGymIrYJRT">https://t.co/UGymIrYJRT</a>
              </p>
              &mdash; Evolve the Law (@EvolveLawNow){" "}
              <a href="https://twitter.com/EvolveLawNow/status/1625260680466124801?ref_src=twsrc%5Etfw">
                February 13, 2023
              </a>
            </blockquote>{" "}
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            ></script>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Bottom;
