import React from "react";
import { Grid, Card, Avatar } from "@mui/material";
// import FacebookIcon from "../common/icons/FacebookIcon";
// import TwitterIcon from "../common/icons/TwitterIcon";
// import InstagramIcon from "../common/icons/InstagramIcon";
import { AiOutlineMail } from "react-icons/ai";

import LinkedinIcon from "../common/icons/LinkedinIcon";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    border: "1px solid transparent",
    transition: "all 250ms ease-in-out",
    // "&:hover": {
    //   border: "1px solid rgba(var(--primary), 1)",
    //   color: palette.primary.main,
    //   "& $iconContainer": {
    //     opacity: 1,
    //   },
    //   "& .description": {
    //     color: palette.primary.main,
    //   },
    // },
  },
  iconContainer: {
    position: "absolute",
    top: "calc(50% - 10px)",
    left: "50%",
    transform: "translate(-50%)",
    opacity: 0,
    transition: "opacity 250ms ease",
    "&:after": {
      content: '" "',
      position: "absolute",
      top: 0,
      marginTop: "calc(-50% - 4px)",
      height: 128,
      width: 128,
      background: "rgba(0,0,0, 0.67)",
      borderRadius: 300,
      overflow: "hidden",
      zIndex: -1,
    },
  },
  iconWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    borderRadius: 4,
    marginRight: 4,
    border: `1px solid ${palette.primary.contrastText}`,
    cursor: "pointer",
  },
}));

const Speakers1 = () => {
  const classes = useStyles();
  const speakerList = [
    {
      name: "Henry Perlstein, Esq.",
      imgUrl: "/assets/images/henryperlstein-color.png",
      imgUrlHover: "/assets/images/henryperlstein-color.png",
      linkedin: "https://www.linkedin.com/in/henryperlstein/",
      email: "mailto:henry@commonlaw.ai",
      bio: "Co-Founder & Chief Education Officer",
    },
    {
      name: "Michael Perlstein",
      imgUrl: "/assets/images/michaelperlstein.png",
      imgUrlHover: "/assets/images/michaelperlstein-color.png",
      linkedin: "https://www.linkedin.com/in/mperlstein1/",
      email: "mailto:michael@commonlaw.ai",
      bio: "Co-Founder & Chief Technology Officer",
    },
  ];

  return (
    <section className="section bg-light-gray items-center" id="team">
      <div className="container flex-column justify-center items-center text-center text-primary">
        <div className="section__header">
          <h2 className="font-normal text-48">Founding Team</h2>
          <p className="font-normal">
            We are experienced legal technologists, AI whisperers, technical
            trainers, solution architects, and support experts. We bring years
            of the needed domain expertise to bridge the technology gap for your
            office.{" "}
          </p>
        </div>
        <Grid container spacing={3}>
          <Grid item sm={2} xs={12}></Grid>
          {speakerList.map((item, ind) => (
            <Grid key={ind} item sm={4} xs={12}>
              <Card
                elevation={3}
                className={clsx(
                  "border-radius-12 px-6 pt-8 pb-4 text-center",
                  classes.card
                )}
              >
                <div className="mb-6 relative">
                  <Avatar
                    src={item.imgUrl}
                    className="h-128 w-128 inline-block"
                  ></Avatar>
                  <div
                    className={clsx(
                      "flex flex-wrap justify-center",
                      classes.iconContainer
                    )}
                  ></div>
                </div>
                <h4 className="m-0 font-medium text-18">{item.name}</h4>
                <p className="description">{item.bio}</p>
                <div className="text-center team-icons">
                  <a href={item.email}>
                    <AiOutlineMail className="text-20" fontSize="small" />
                  </a>
                  <a href={item.linkedin} target="_blank" rel="noreferrer">
                    <LinkedinIcon className="text-20" fontSize="small" />
                  </a>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default Speakers1;
