import React, { useState, useEffect } from "react";
import { debounce } from "../../utils";
import ScrollTo from "../common/ScrollTo";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  containerHolder: {
    background: 'url("/assets/images/svg/bg.svg") no-repeat !important',
    backgroundPosition: `bottom !important`,
    backgroundSize: `contain !important`,
    // padding: `10rem !important`,
  },
}));

const Intro6 = () => {
  const classes = useStyles();

  const [isTop, setIsTop] = useState(true);
  const [isClosed, setIsClosed] = useState(true);

  let scrollableElement = document.querySelector(".scrollable-content");
  if (!scrollableElement) scrollableElement = window;

  let handleScrollRef = null;
  let toggleIcon = isClosed ? "menu" : "close";

  const handleScroll = () => {
    return debounce(({ target: { scrollTop } }) => {
      let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
      setIsTop(isCurrentTop);
    }, 20);
  };

  handleScrollRef = handleScroll();

  const close = () => {
    setIsClosed(false);
  };

  useEffect(() => {
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScrollRef);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScrollRef);
      }
    };
  }, [scrollableElement, handleScrollRef]);
  return (
    <section className="section pt-30" id="intro6">
      <div className={classes.containerHolder}>
        <div
          className={clsx(
            "container flex-column justify-center items-center text-white",
            classes.container
          )}
        >
          <h1 className="font-normal text-center max-w-550 text-36 mb-2 mt-0 intro-text-p">
            <span className={"font-bold"}>AI Training</span>
            <span className={"font-normal"}> and </span>
            <span className={"font-bold"}>Consulting</span> For{" "}
            <span className={"italictext"}>Access To Justice</span>
          </h1>
          <p className="font-normal text-center max-w-600 text-20 my-6 text-white">
            We are the first technology consultancy mission-built to equip
            providers of <span className={"italictext"}>access to justice</span>{" "}
            with cutting-edge AI solutions.
          </p>
          {/* <p className="font-normal text-center max-w-550 text-20 my-6 text-white">
            We are the first and only{" "}
            <span className={"pbllc"}>public benefit company</span>{" "}
            mission-built to provide cutting-edge AI solutions to{" "}
            <span className={"intro-text-p"}>access to justice</span> providers.
            We not help offices utilize these transformative technologies, but
            also help them acquire funding to cover their costs.
          </p> */}
          {/* <ScrollTo to="getstarted" onScroll={close}>
            <Button
              className="box-shadow-none px-8 hover-bg-secondary capitalize"
              variant="outlined"
              color="secondary"
            >
              Free Ai Consultation
            </Button>
          </ScrollTo> */}
        </div>
      </div>
    </section>
  );
};

export default Intro6;
