import React from "react";

import { Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    background: "transparent !important",
    border: "none !important",
    boxShadow: "none !important",
    // "& .icon": {
    //   fontSize: 52,
    // },
  },
}));

const LegalAI = () => {
  const classes = useStyles();

  const domainsList = [
    {
      icon: "dashboard",
      title: "CoCounsel by Casetext®",
    },
    {
      icon: "dashboard",
      title: "WestLaw Edge®",
    },
    {
      icon: "perm_data_setting",
      title: "HarveyAI",
    },
    {
      icon: "storage",
      title: "Claudius Legal Intelligence",
    },
    {
      icon: "stay_primary_portrait",
      title: "Spellbook by Rally®",
    },
    {
      icon: "dashboard",
      title: "HighQ by Thomson Reuters®",
    },
    {
      icon: "dashboard",
      title: "ChatGPT by OpenAI®",
    },
    { icon: "dashboard", title: "LawDroid Copilot®" },
    { icon: "dashboard", title: "LawDroid® Builder" },
    { icon: "dashboard", title: "EsquireTek®" },
    { icon: "dashboard", title: "PDF Check by Brightflag®" },
  ];

  return (
    <section className="section bg-light-gray items-center" id="legalai">
      <div className="container flex-column justify-center items-center text-center text-primary">
        <div className="section__header">
          <h2 className="font-normal text-48">
            The Legal AI Arms Race Has Begun
          </h2>
          <p className="font-normal">
            Artificial Intelligence (AI) tools are transforming nearly every
            aspect of the legal profession - from legal research and drafting,
            to e-discovery and deposition transcription. The "Legal AI arms
            race" has begun!{" "}
          </p>
          {/* <br /> */}
          <p className="font-normal">
            Powerful new attorney assistants, legal chatbots, generative AI
            programs, and predictive analytics platforms seem to be debuting
            every week.
          </p>
          {/* <br /> */}
          <p className="font-normal">
            Some notable launches of the past several months include:
          </p>
        </div>
        <Grid container spacing={2} alignContent="stretch">
          {domainsList.map((domain) => (
            <Grid item lg={2} md={3} sm={12} key={domain.title}>
              <Card className={clsx("card h-full", classes.card)}>
                <CardContent className="flex-column justify-between min-h-full">
                  {/* <div> */}
                  <div className="flex-grow">
                    {/* <div className="text-center mb-4">
                      <Icon className="icon">{domain.icon}</Icon>
                    </div> */}
                    <h4 className="font-medium">{domain.title}</h4>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <p className="font-bold">This is only the beginning. </p>
      </div>
    </section>
  );
};

export default LegalAI;
