import React from "react";
import Grid from "@mui/material/Grid";
import DemoForm from "./DemoForm";

const RequestDemo = () => {
  return (
    <section
      className="section section-bg-light-primary items-center"
      id="getstarted"
    >
      <div className="container">
        <div className="container flex-column justify-center items-center text-center text-primary">
          <h2 className="font-normal text-48">Request A Demo</h2>
          <p className="font-normal">
            Sign Up To Request A Demo of the{" "}
            <span className={"font-bold"}>CommonLawAI</span> software
          </p>
          <div className="flex items-center max-w-550 relative form">
            <Grid item xs={12}>
              <DemoForm />
            </Grid>
          </div>
          <p className="font-normal italictext">
            Disclaimer: We are not a law firm and do not, in any way, provide
            legal advice or counsel. The information provided on this website
            does not, and is not intended to, constitute legal advice; instead,
            all information, content, and materials available on this site are
            for general informational purposes only.
          </p>
        </div>
      </div>
    </section>
  );
};

export default RequestDemo;
