import React from "react";

// import { Button, Icon, Card, CardContent, Grid } from "@mui/material";
import { Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    background: "transparent !important",
    border: "none !important",
    boxShadow: "none !important",
    "& .icon": {
      fontSize: 52,
    },
  },
}));
const Clients = () => {
  const classes = useStyles();

  const a2jList = [
    {
      icon: "/assets/images/legalaid.png",
      title: "Legal Aid Providers",
    },
    {
      icon: "/assets/images/publicdefender.png",
      title: "Public Defender Offices",
    },
    {
      icon: "/assets/images/publicinterestlawfirms.png",
      title: "Public Interest Law Firms",
    },
    {
      icon: "/assets/images/nonprofitlawfirms.png",
      title: "Nonprofit Law Firms",
    },
    {
      icon: "/assets/images/lawschoolclinics.png",
      title: "Law School Clinics",
    },
  ];

  // const lawfirmList = [
  //   {
  //     icon: "dashboard",
  //     title: "Solo and Small Firms",
  //   },
  //   {
  //     icon: "dashboard",
  //     title: "Medium-Sized Firms",
  //   },
  //   {
  //     icon: "perm_data_setting",
  //     title: "Full-Service and Large Law Firms",
  //   },
  // ];
  return (
    <section className="section items-center" id="clients">
      <div className="container flex-column justify-center items-center text-center text-primary">
        <div className="section__header">
          <h2 className="font-normal text-48">Who We Serve</h2>
          <p className="font-normal">
            We work with law firms and legal services providers of all sizes. We
            help attorneys deliver better legal services to those they serve. We
            have special funding opportunities for providers of{" "}
            <span className={"italictext"}>access to justice</span> including:
          </p>
          <Grid container spacing={2} justify="space-between">
            {a2jList.map((item, ind) => (
              <Grid key={ind} item lg={4} md={4} sm={6} xs={12}>
                <Card className={clsx("h-full", classes.card)}>
                  <CardContent className="flex-column justify-between min-h-full">
                    <div className="text-center max-w-350 mx-auto">
                      {/* <FontAwesomeIcon
                      icon={item.icon}
                      color="primary"
                      className="text-72"
                    /> */}
                      {/* {item.icon} */}
                      {/* <item.icon color="primary" className="text-72" /> */}
                      <div className="text-center mb-0">
                        <img
                          src={item.icon}
                          alt={item.title}
                          width="40"
                          height="40"
                        />
                      </div>
                      <h4 className="font-medium">{item.title}</h4>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <p className="font-normal">
            We also provide services for{" "}
            <span className={"font-bold"}>for-profit law firms</span> in all
            practice areas and of all sizes areas
          </p>

          {/* <Grid container spacing={6} justify="space-between">
            {lawfirmList.map((item, ind) => (
              <Grid key={ind} item lg={4} md={4} sm={6} xs={12}>
                <Card className={clsx("h-full", classes.card)}>
                  <CardContent className="flex-column justify-between min-h-full">
                    <div className="text-center max-w-350 mx-auto">
                     
                      <h4 className="mt-8 font-medium text-19">{item.title}</h4>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid> */}
        </div>
      </div>
    </section>
  );
};

export default Clients;
