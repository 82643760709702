import React, { useState } from "react";
import { Button } from "@mui/material";

const Form = () => {
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    "entry.874535788": "",
    "entry.351987181": "",
    "entry.1502967149": "",
  });

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmit(true);

    let url = `https://docs.google.com/forms/u/1/d/e/1FAIpQLSdZhso16VsyH87jWPR-Rq2ta-CVu5p6sJkb4Lj19uMJImc0qA/formResponse?entry.874535788=${formData["entry.874535788"]}&entry.351987181=${formData["entry.351987181"]}&entry.1502967149=${formData["entry.1502967149"]}`;

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  return (
    <div className="contactFormWrapper">
      <div className="formheader"></div>
      <div className="formcontact">
        {submit ? (
          <div className="afterForm">
            Thank you, a member of our team will get back to you shortly
          </div>
        ) : (
          <form onSubmit={handleSubmit} target="_self">
            <fieldset>
              <label htmlFor="entry.874535788">Name:</label>
              <input
                type="text"
                name="entry.874535788"
                onChange={handleInputData("entry.874535788")}
                value={formData["entry.874535788"]}
              />
            </fieldset>

            <fieldset>
              <label htmlFor="entry.351987181">Organization:</label>
              <input
                type="text"
                name="entry.351987181"
                onChange={handleInputData("entry.351987181")}
                value={formData["entry.351987181"]}
              />
            </fieldset>

            <fieldset>
              <label htmlFor="entry.1502967149">E-mail:</label>
              <input
                required
                type="email"
                name="entry.1502967149"
                onChange={handleInputData("entry.1502967149")}
                value={formData["entry.1502967149"]}
              />
            </fieldset>

            <Button
              type="submit"
              className="box-shadow-none px-8 hover-bg-secondary capitalize submit-form"
              variant="contained"
              color="secondary"
            >
              Get Started
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Form;
