import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="section items-center" id="privacy">
      <div className="container flex-column justify-center items-center text-primary">
        <div className="section__header">
          <h2 className="font-normal text-48 text-center">Privacy Policy</h2>
          <p className="font-normal text-left">
            <p className={"font-bold"}>Effective Date: March 5, 2023</p>
            <p className={"font-bold"}>Last Updated Date: March 5, 2023</p>
            <p>
              Fundseek, LLC (“Fundseek”, “Company”, “we”, “us” or “our”) is
              committed to protecting your privacy.
            </p>
            <p>
              We have prepared this Privacy Policy to describe to you our
              practices regarding the personal information we collect from users
              of this website (the “Site“).
            </p>
            <p>
              This Privacy Policy may be updated from time to time for any
              reason. We will notify you of any changes to our Privacy Policy by
              posting the new Privacy Policy here{" "}
              <a href="/privacypolicy">
                https://www.commonlaw.ai/privacypolicy
              </a>{" "}
              and we will change the “Last Updated” date above. You should
              consult this Privacy Policy regularly for any changes.
            </p>
            <h3 className={"text-center"}>Types of Data We Collect.</h3>
            <p>We collect personal information as described below:</p>
            <h4>Information You Provide to Us</h4>
            <p>
              We may collect your contact information, such as your first name,
              last name, email addresses, company name, number of employees at
              your company, mailing addresses, and your phone numbers. We may
              also collect information about you that you provide when you
              request information from us or otherwise correspond with us.
            </p>
            <h4>User Generated Content.</h4>
            <p>
              We may collect content, including feedback, messages, or comments,
              or other information that you may provide or create when you
              interact with the Site.{" "}
            </p>
            <p>
              We may collect other information about you that is not
              specifically listed here and will use it in accordance with this
              Privacy Policy.
            </p>
            <h4>Information Collected via Our Web Servers</h4>
            <p>
              To make the Site more useful to you, our web servers (which may be
              hosted by a third-party service provider) collect information from
              you, including your browser type, operating system, Device ID,
              Internet Protocol (“IP”) address (a number that is automatically
              assigned to your computer when you use the Internet, which may
              vary from session-to-session), domain name, and a time stamp for
              your visit. We collect this information in server logs and by
              using cookies, web beacons, and similar tracking technologies.
            </p>
            <h4>Information Collected from Third Parties.</h4>
            <p>
              We may collect personal information about you from third parties,
              such as publicly-accessible websites and third-party partners.We
              may add this information to the information we have already
              collected from you via the Site and will handle it in accordance
              with this Privacy Policy.
            </p>
            <h3 className={"text-center"}>Use of Your Personal Information</h3>
            <p>
              We use your personal information for the following purposes and as
              otherwise described in this Privacy Policy, or on the Site at the
              time of collection:
            </p>
            <h4>To Operate the Site.</h4>
            <p>
              We use your personal information to: provide, operate and improve
              the Site; improve the quality of experience when you interact with
              the Site; communicate with you about the Site, including by
              sending you administrative email notifications, such as security,
              support, and maintenance advisories; and respond to your requests,
              questions, and feedback related to the Site.
            </p>
            <h4>To Process Your Transactions.</h4>
            <p>
              We use your personal information to fulfill the orders you make
              through the Site.
            </p>
            <h4>For Research and Development.</h4>
            <p>
              We analyze use of the Site to study trends and users’ movements
              around the Site, gather demographic information about our user
              base, improve the Sites and develop new products and services.
            </p>
            <h4>To Send You Marketing Communications.</h4>
            <p>
              We may send you marketing communications, but you may opt out of
              receiving them as described in the Email Communication section
              below.
            </p>
            <h4>To Create Anonymized Data.</h4>
            <p>
              We may create aggregated and other anonymous data from our users’
              information. We convert personal information into anonymized data
              by removing information that makes the data personally
              identifiable. We may use this anonymized data by sharing it with
              third parties to understand and improve the Site, promote our
              business, and for other lawful business purposes.
            </p>
            <h4>For Compliance, Fraud Prevention, and Safety.</h4>
            <p>
              We may use your personal information and share it with government
              officials, law enforcement or private parties, as we believe
              appropriate to: protect our, your or others’ rights, privacy,
              safety or property (including by making and defending legal
              claims); protect, investigate, and deter against fraudulent,
              harmful, unauthorized, unethical or illegal activity; comply with
              applicable laws, lawful requests, and legal processes, such as to
              respond to subpoenas or requests from government authorities; and
              where permitted by law in connection with a legal investigation.
            </p>
            <h3 className={"text-center"}>
              Disclosure of Your Personal Information
            </h3>
            <p>
              We do not share your personal information with third parties
              without your consent, except in the following circumstances or as
              described in this Privacy Policy:
            </p>
            <h4>Users.</h4>
            We do not share your personal information with other Users, except
            as may be necessary for the sole purpose of providing the Site. We
            do not share your personal information with other Users without
            first obtaining your consent to do so.
            <h4>Affiliates.</h4>
            <p>
              {" "}
              We may share some or all of your personal information with our
              parent company, subsidiaries, or other companies under a common
              control (“Affiliates”), in which case we will require our
              Affiliates to honor this Privacy Policy.
            </p>
            <h4>Service Providers.</h4>
            <p>
              We may share your personal information with third-party companies
              and individuals as needed for them to provide us with services
              that help us provide the Site or support our business activities
              (such as website analytics, email delivery, payment processing,
              marketing, advertising, IT providers, technical support, legal
              advice, and other professional advice).
            </p>
            <h4>For Compliance, Fraud Prevention, and Safety.</h4>
            <p>
              We may share your personal information for the compliance, fraud
              prevention, and safety purposes described above in the Use section
              of this Privacy Policy.
            </p>
            <h4>Your Choices Regarding Information.</h4>
            <p>
              You have several choices regarding the use of information on the
              Site:
            </p>
            <h4>Cookies and Similar Technologies.</h4>
            <p>
              We may allow service providers and other third parties to use
              cookies and similar technologies to track your browsing activity
              over time and across the Site and third-party websites. Some
              Internet browsers may be configured to send “Do Not Track” signals
              to the online services that you visit. We currently do not respond
              to “Do Not Track” or similar signals. To find out more about “Do
              Not Track,” please visit http://www.allaboutdnt.com.
            </p>
            <h4>User Generated Content</h4>
            <p>
              We may make available on the Site, or link to, features that allow
              you to share information online (e.g., on our blog, in comment
              areas, etc.). Please be aware that whenever you voluntarily
              disclose personal information online, that information becomes
              public and can be collected and used by others. We have no control
              over, and take no responsibility for, the use, storage or
              dissemination of such publicly-disclosed personal information. By
              posting personal information online in public forums, you may
              receive unsolicited messages from other parties.
            </p>
            <h4>Third-Party Websites.</h4>
            <p>
              The Site may contain links to other websites and services operated
              by third parties. These links are not an endorsement of, or
              representation that we are affiliated with, any third party. We do
              not control third-party websites, applications or services, and
              are not responsible for their actions. Other websites and services
              follow different rules regarding their collection, use, and
              sharing of your personal information. We encourage you to read
              their privacy policies to learn more.
            </p>
            <h4>Security Practices.</h4>
            <p>
              The security of your personal information is important to us. We
              employ a number of organizational, technical and physical
              safeguards designed to protect the personal information we
              collect. However, security risk is inherent in all internet and
              information technologies and we cannot guarantee the security of
              your personal information. If you have a user account for the
              Site, you are solely responsible for maintaining and protecting
              the confidentiality of your account login information, including
              the password and other account information in your possession.
            </p>
            <h4>Use of the Site by Visitors Outside the United States.</h4>
            <p>
              The Site is operated from the United States. Any information we
              obtain about you in connection with your use of the Site may be
              processed and stored in, and subject to the laws of, the United
              States or other countries. Privacy laws in the locations where we
              process and store your personal information may not be as
              protective as the privacy laws in your home country.
            </p>
            <h3 className={"text-center"}>A Note About Children</h3>
            <p>
              We do not intentionally gather personal information from visitors
              who are under the age of 13. If a child under 13 submits personal
              information to us and we learn that the personal information is
              the information of a child under 13, we will attempt to delete the
              information as soon as possible. If you believe that we might have
              any personal information from a child under 13, please contact us
              at contact@commonlaw.ai.
            </p>
            <h3 className={"text-center"}>How to Contact Us.</h3>
            <p>
              Please direct any questions or comments about this Policy to{" "}
              <a href="mailto:contact@commonlaw.ai">contact@commonlaw.ai</a>.
            </p>
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
