import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { darken } from "@mui/material";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  section: {
    background: "#011C3A",
    color: palette.primary.contrastText,
  },
  iconWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    borderRadius: 4,
    marginRight: 12,
    border: `1px solid ${palette.primary.contrastText}`,
    cursor: "pointer",
  },
  link: {
    borderRadius: 4,
    "&:hover": {
      background: darken("#011C3A", 0.2),
    },
  },
}));

const Footer2 = () => {
  const classes = useStyles();

  return (
    <section className={`section ${classes.section}`} id="contact4">
      <div className="container">
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className="footer1__about">
              {" "}
              <a
                className={"logo-link text-white font-bold text-30"}
                href={"/"}
              >
                CommonLawAI
                {/* <img
                src="/assets/images/CommonLawAI-wordmark-white.png"
                alt="© 2023 Common Law AI"
              />*/}
              </a>
              <p className="my-6 max-w-350 text-inherit justify">
                We are the first technology consultancy mission-built to equip
                providers of{" "}
                <span className={"italictext"}>access to justice</span> with
                cutting-edge AI solutions
              </p>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className="footer1__about"></div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className="footer1__links">
              {" "}
              <ul>
                <li>
                  <a
                    href="mailto:contact@commonlaw.ai"
                    className="mx-1 font-bold"
                  >
                    Email Us
                  </a>
                </li>{" "}
                <li>
                  <NavLink to="privacypolicy" className="mx-1 font-bold">
                    Privacy Policy
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="requestdemo" className="mx-1 font-bold">
                    Request A Demo
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="footer1__copyright">
              <div className="mt-4 mb-6 ml--4">© 2023 All Rights Reserved.</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Footer2;
