import React from "react";
import { Icon, Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { GiArtificialIntelligence } from "react-icons/gi";
import { TbPlugConnected } from "react-icons/tb";
import {
  MdOutlineDashboardCustomize,
  MdOutlineTune,
  MdSupportAgent,
} from "react-icons/md";
import { SiKnowledgebase } from "react-icons/si";
import { BsEasel } from "react-icons/bs";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    background: "transparent !important",
    border: "none !important",
    boxShadow: "none !important",
    "& .icon": {
      fontSize: 52,
    },
  },
}));

const Services = () => {
  const classes = useStyles();

  const serviceList = [
    {
      icon: <MdOutlineDashboardCustomize />,
      title: "AI Consultative Services",
      description:
        "Lorem ipsum dolor sit amet conse ctetur adipi sicing elit. Doloribus numquam quis.",
    },
    {
      icon: <BsEasel />,
      title: "Tailored Legal AI Training",
      description:
        "Lorem ipsum dolor sit amet conse ctetur adipi sicing elit. Doloribus numquam quis.",
    },
    {
      icon: <MdSupportAgent />,
      title: "Support From Human Beings",
      description:
        "Lorem ipsum dolor sit amet conse ctetur adipi sicing elit. Doloribus numquam quis.",
    },
    {
      icon: <GiArtificialIntelligence />,
      title: "Legal AI Implementations",
      description:
        "Lorem ipsum dolor sit amet conse ctetur adipi sicing elit. Doloribus numquam quis.",
    },
    {
      icon: <SiKnowledgebase />,
      title: "Custom Legal AI Knowledge-bases",
      description:
        "Lorem ipsum dolor sit amet conse ctetur adipi sicing elit. Doloribus numquam quis.",
    },
    {
      icon: <MdOutlineTune />,
      title: "Fine-Tuned Legal AI Applications",
      description:
        "Lorem ipsum dolor sit amet conse ctetur adipi sicing elit. Doloribus numquam quis.",
    },

    {
      icon: <TbPlugConnected />,
      title: "Legal AI Integrations",
      description:
        "Lorem ipsum dolor sit amet conse ctetur adipi sicing elit. Doloribus numquam quis.",
    },
  ];

  return (
    <section className="section bg-light-gray items-center" id="solutions">
      <div className="container flex-column justify-center items-center text-center text-primary">
        <div className="section__header">
          <h2 className="font-normal text-48">Tailored Legal AI Solutions</h2>
          <p className="font-normal">
            Our dynamic team empowers you to better and more efficiently
            leverage these powerful tools.{" "}
          </p>
          {/* <br /> */}
          <p className="font-normal">
            Our job is to help your office identify, understand, and implement
            the best available AI solutions for your practice. Our expert
            trainers work to ensure that your staff is comfortable using the
            technology, and our support staff is standing by to help you
            troubleshoot any challenges that arise.
          </p>
        </div>
        <Grid container spacing={1} justify="space-between">
          {serviceList.map((item, ind) => (
            <Grid key={ind} item lg={3} md={3} sm={12} xs={12}>
              <Card className={clsx("h-full", classes.card)}>
                <CardContent className="flex-column justify-between">
                  <div className="text-center max-w-350 ">
                    {/* <FontAwesomeIcon
                      icon={item.icon}
                      color="primary"
                      className="text-72"
                    /> */}
                    {/* {item.icon} */}
                    {/* <item.icon color="primary" className="text-72" /> */}
                    <div className="text-center mb-1">
                      <Icon className="icon">{item.icon}</Icon>
                    </div>
                    <h4 className="font-medium">{item.title}</h4>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <p className="font-bold">
          You tell us what you need, will tell you how we can make it happen.
        </p>
      </div>
    </section>
  );
};

export default Services;
